import {timeFormat, numberFormat} from "@custom/index";

// 小区管理 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "activityName",
        label: "活动名称",
        width: 200,
    },
    {
        prop: "activityType",
        label: "活动类型",
        width: 200,
    },
    {
        prop: "startDate",
        label: "开始时间",
        width: 200,
    },
    {
        prop: "endDate",
        label: "结束时间",
        width: 200,
    },
    {
        prop: "status",
        label: "活动状态",
        width: 200,
    },
    {
        prop: "activityIntroduce",
        label: "活动介绍",
        showOverflowTooltip: true,
    },
];