<template>
    <section class="activity-management" @click.stop="selectclick">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" placeholder="请输入活动名称" style="margin: 0 7px; width: 250px"
                      size="small"/>
            <el-select ref="activityTypeRef"   v-model="formSearch.activityType" placeholder="请选择活动类型" style="margin-right: 10px" size="small">
                <el-option label="全部" :value="-1"/>
                <el-option v-for="item in activityTypeList" :key="item.code" :label="item.codeName"
                           :value="item.code"/>
            </el-select>
            <el-select ref="statusRef"   v-model="formSearch.status" placeholder="请选择活动状态" style="margin-right: 10px" size="small">
                <el-option label="全部" :value="-1"/>
                <el-option label="正常" :value="0"/>
                <el-option label="结束" :value="1"/>
            </el-select>
            <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small" style="margin: 0 7px;">重置</el-button>
            <el-button type="primary" size="small" style="margin: 0 7px;" icon="el-icon-plus"
                       @click="saveActivity(null)">
                添加活动
            </el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getAticityListTableData" :columns="tableColumn"
                   :query="formSearch" :height="790">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="openInfo(row)">详情</span>
                        <span class="table-btn" v-if="row.status==='正常'" @click="saveActivity(row)">编辑</span>
                        <span class="table-btn" v-if="row.status==='正常'" @click="endActivity(row)">结束</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <dialog-save-activity ref="dialogSaveActivity" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import {tableColumn} from "./data";
import {getAticityList, finishedAticity} from "@/api/activity-management";
import {MessageSuccess} from "@custom/message";

export default {
    name: "activity-management",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                activityType: -1,
                status: -1,
            },
            activityTypeList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    async mounted() {
        this.activityTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "601100");
    },
    components: {
        dialogSaveActivity: () => import("./components/dialog-save-activity"),
    },
    methods: {
        getAticityListTableData(params) {
            return getAticityList(params);
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        handleReset() {
            this.formSearch = {keyword: null, activityType: -1, status: -1,};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openActivity(data, type) {
            this.$refs["dialogSaveActivity"].openDialog(data, type);
        },
        saveActivity(data) {
            this.openActivity(data, data ? 2 : 1);
        },
        openInfo(data) {
            this.openActivity(data, 0);
        },
        endActivity(data) {
            const that = this;
            const {uuid} = data;
            this.$confirm('是否永久关闭此活动?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading(loadingOptions);
                finishedAticity({uuid}).then(res => {
                    MessageSuccess("关闭成功");
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },
      // // 2024-4-17-新增点击空白关闭组件
      selectclick(){
        this.$refs['activityTypeRef'].blur();
        this.$refs['statusRef'].blur();
      },
    },
    watch: {}
}
</script>


<style lang="scss" scoped>
.activity-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
        }
    }
}
</style>